<template>
    <div class="section">
        <div v-if="page" class="block-info">
            <div class="inner-page"><h1 v-if="page?.title" class="inner-page__title">{{page.title.rendered}}</h1>
                <div v-if="page?.content" v-html="page.content.rendered"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "../store";

    export default {
        name: "Privacy",
        props: {active: {type: String}},
        computed: {
            page() {
                return store.pp_page_content
            }
        }
    }
</script>

<style scoped>
</style>